import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../../components/Layout/Layout";
import pathBuilder from "../../utils/pathBuilder";
import ContentfulRenderer from "../../components/System/ContentfulRenderer";
import BreadCrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import SecondaryNavigation from "./components/SecondaryNavigation";
import Styled from "./styled";
import { BlogPostSchema } from "../../components/Seo/Schemas";
import SocialShare from "../../components/SocialShare";
import UiLayout from "../../components/UI/Layout";
import Banner from "../../components/banner";
import RelatedBooksComponent from "../../components/RelatedBooks";
import QuizComponent from "../../components/QuizComponent";
import CommentsBlock from "../../components/CommentsBlock/CommentsBlock";
import { useAppContext } from "../../context/appProvider";

const LanguagePill = (props) => {
  const ctx = useAppContext();

  const { state, getAccessTokenSilently } = ctx;
  const { BackendGraph, pill, altPill, defaultFbImage } = props.data;

  const {
    title,
    content,
    node_locale,
    contentful_id,
    __typename,
    slug,
    seoDescription,
    difficulty,
    createdAt,
    seoTitle,
    disableComments,
    outro,
    relatedExercises,
    relatedQuiz,
    updatedAt,
    titleBeautiful,
    intro,
  } = pill;

  const lastDate = new Date(updatedAt);

  // constructing environment.
  const environment = {
    lang: node_locale,
    id: contentful_id,
    path: pathBuilder(node_locale, __typename, slug),
    theme: "orange",
    altHrefLang: [
      {
        lang: node_locale,
        path: pathBuilder(node_locale, __typename, slug),
      },
      {
        lang: altPill.node_locale,
        path: pathBuilder(
          altPill.node_locale,
          altPill.__typename,
          altPill.slug,
        ),
      },
    ],
    seo: {},
  };
  if (seoDescription) {
    environment.seo.description = seoDescription;
  }
  if (seoTitle) {
    environment.seo.title = seoTitle;
  } else {
    environment.seo.title = title;
  }

  const secondaryMenu = [];

  if (content) {
    secondaryMenu.push({
      title: "Theory",
      link: "explanations",
    });
  }
  if (relatedExercises || relatedQuiz) {
    secondaryMenu.push({
      title: "Exercises",
      link: "exercises",
    });
  }

  if (outro && outro.raw) {
    secondaryMenu.push({
      title: "Next steps",
      link: "next",
    });
  }
  if (!disableComments) {
    secondaryMenu.push({
      title: "Ask a question",
      link: "comments",
      overline: "new",
    });
  }

  if (!disableComments && BackendGraph?.comments?.length > 0) {
    secondaryMenu.push({
      title: `Other people's (${BackendGraph.comments.length}) questions`,
      link: "next",
    });
  }
  const displayTitle = titleBeautiful || title;

  return (
    <Layout environment={environment}>
      <BlogPostSchema
        path={environment.path}
        title={displayTitle}
        description={seoDescription}
        updatedAt={updatedAt}
        createdAt={createdAt}
        imageUrl={defaultFbImage.publicURL}
      />
      <Styled.ConceptIntro>
        <Styled.ConceptIntroContainer>
          <h1>{displayTitle}</h1>
          {secondaryMenu ? <SecondaryNavigation menu={secondaryMenu} /> : null}
          {intro || seoDescription ? (
            intro ? (
              <ContentfulRenderer
                json={intro}
                lang={environment.lang}
                id="intro"
              />
            ) : (
              <div id="intro">{seoDescription}</div>
            )
          ) : null}
          <BreadCrumbs
            lang={environment.lang}
            type={__typename}
            path={environment.path}
            title={title}
            meta={"Last edit: " + lastDate.toDateString()}
          />
          <SocialShare cta={"Share&Save"} environment={environment} />
        </Styled.ConceptIntroContainer>
      </Styled.ConceptIntro>
      <UiLayout.Content>
        <UiLayout.BodyContainer>
          <UiLayout.Main id="grammar-content">
            <Styled.SectionContainer>
              <h2 className="header">Learn the theory</h2>
              <ContentfulRenderer
                json={content}
                lang={environment.lang}
                id="explanations"
              />
            </Styled.SectionContainer>

            {relatedQuiz ? (
              <Styled.SectionContainer id="exercises">
                <h2 className="header">Practice with exercises</h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: relatedQuiz.description.childMarkdownRemark.html,
                  }}
                />
                <QuizComponent quiz={relatedQuiz} padding="none" />
              </Styled.SectionContainer>
            ) : relatedExercises ? (
              relatedExercises.map((item, index) => (
                <div key={index}>
                  <Styled.SectionContainer id="exercises">
                    <div>
                      <h2 className="header">Practice with exercises</h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.content.childMarkdownRemark.html,
                        }}
                      />

                      <h2 className="header">Solutions</h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.answers.childMarkdownRemark.html,
                        }}
                      />
                    </div>
                  </Styled.SectionContainer>
                </div>
              ))
            ) : null}
            {outro ? (
              <Styled.SectionContainer id="next">
                <h2 className="header">Some ideas to keep learning</h2>
                <ContentfulRenderer
                  json={outro}
                  lang={environment.lang}
                  id="explanations"
                />
              </Styled.SectionContainer>
            ) : null}
            {!disableComments && (
              <CommentsBlock
                getToken={() => getAccessTokenSilently()}
                user={state?.user}
                elementId={contentful_id}
              />
            )}
          </UiLayout.Main>

          <UiLayout.Aside>
            <Banner />
          </UiLayout.Aside>
        </UiLayout.BodyContainer>
      </UiLayout.Content>
      <Styled.BookSectionWrapper>
        <UiLayout.Content>
          <div className="header-row">
            <h3>Related practice books!</h3>
            <Link to="/en/shop">See all books</Link>
          </div>
        </UiLayout.Content>

        <RelatedBooksComponent level={difficulty} environment={environment} />
      </Styled.BookSectionWrapper>
    </Layout>
  );
};

export default LanguagePill;

export const langPillQuery = graphql`
  query languagePillQuery($lang: String!, $id: String!, $altLang: String!) {
    pill: contentfulGrammarPill(
      contentful_id: { eq: $id }
      node_locale: { eq: $lang }
    ) {
      title
      slug
      node_locale
      contentful_id
      seoTitle
      disableComments
      difficulty
      seoDescription
      updatedAt
      createdAt
      titleBeautiful

      content {
        raw
        references {
          ... on ContentfulAsset {
            id
            title
            file {
              url
              fileName
              contentType
            }
            contentful_id
            spaceId
            locale: node_locale
            __typename
            gatsbyImageData
          }
          ... on ContentfulBook {
            id
            title
            slug
            sku
            price
            numberOfPages
            level
            contentful_id
            contentLanguage
            fileIdSnipcart
            productId
            __typename

            format
            image {
              gatsbyImageData(width: 300, layout: CONSTRAINED)
              file {
                fileName
                url
                contentType
              }
            }
            spaceId
            subtitle
          }
          ... on ContentfulGrammarPill {
            id
            title
            titleBeautiful
            slug
            node_locale
            __typename

            difficulty
            contentful_id
            spaceId
          }
        }
      }
      intro {
        raw
        references {
          ... on ContentfulGrammarPill {
            id
            title
            slug
            contentful_id
          }
          ... on ContentfulPodcastEpisode {
            id
            contentful_id
            slug
            title
          }
        }
      }
      outro {
        raw
        references {
          title
          slug
          contentful_id
        }
      }
      relatedExercises {
        title
        content {
          childMarkdownRemark {
            html
          }
        }
        answers {
          childMarkdownRemark {
            html
          }
        }
      }
      relatedQuiz {
        ...QuizFragment
      }
      __typename
    }
    altPill: contentfulGrammarPill(
      contentful_id: { eq: $id }
      node_locale: { eq: $altLang }
    ) {
      title
      slug
      node_locale
      __typename
    }
    defaultFbImage: file(relativePath: { eq: "TDOA-SOCIAL-DEFAULT.png" }) {
      publicURL
    }
  }
`;
