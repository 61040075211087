import React, { useEffect, useState } from "react";
import * as Scroll from "react-scroll";
import { SecondaryNavigationContainer } from "../styled";
import { Fragment } from "react";
import styled from "styled-components";

const Overline = styled.div`
  position: absolute;
  right: 0;
  top: -6px;
  padding: 2px 3px;
  display: inline-block;
  line-height: 1;
  background: ${(props) => props.theme.contrast_primary};
  z-index: 2;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 1px;
`;

const MenuItem = styled.li`
  position: relative;
`;

const NavItem = (props) => {
  const { link, title, overline, offset = -180 } = props.item;
  const ScrollLink = Scroll.Link;
  return (
    <Fragment>
      <MenuItem>
        <ScrollLink
          to={link}
          activeClass="active"
          spy={true}
          smooth={true}
          offset={offset}
          duration={300}
        >
          {overline && <Overline>{overline}</Overline>}
          {title}
        </ScrollLink>
      </MenuItem>
      <div className="separator"></div>
    </Fragment>
  );
};

const SecondaryNavigation = (props) => {
  const { menu } = props;
  const [headerHeight, set_headerHeight] = useState(0);

  useEffect(() => {
    const realHeaderHeight =
      document.getElementById("header-wrapper").clientHeight;
    if (realHeaderHeight) {
      set_headerHeight(realHeaderHeight);
    }
  }, []);
  return (
    <SecondaryNavigationContainer
      id="secondary-navigation"
      style={{ top: headerHeight }}
    >
      <ul>
        {menu.map((item, index) => (
          <NavItem item={item} key={index} />
        ))}
      </ul>
    </SecondaryNavigationContainer>
  );
};

export default SecondaryNavigation;
